<div class="topnav" id="myTopnav" >
  <img src="/projects/state-of-local-news/explore/assets/Images/logo-1.png" alt="" class="logo" (click)="GotoHome()">
  <a  class="icon mobileIcon" (click)="myFunction()">
    <i class="fa fa-bars"></i>
  </a>
  <div class="headerNav">
    <a id="home"  class="skewDiv active" (click)="GotoHome1()" >
      <span>National <br>Outlook</span>
    </a>
    <a id="newspaper" class="skewDiv megaMenuTrigger " (click)="isMegaMenuVisible = !isMegaMenuVisible" >
      <span class="d-flex  flex-column text-nowrap">
        <div class="d-block">Explore Your <br>State <i class="fa fa-angle-down" style="font-size: 6px;"></i></div>  
     </span>
    </a>
    <!-- (mouseleave)="hideMegaMenu()" -->
    <!-- (mouseenter)="showMegaMenu()" (mouseleave)="isMegaMenuVisible = false"
    (mouseenter)="showMegaMenu()" (mouseleave)="hideMegaMenu()" -->
    <div class="megaMenu  "  [class.show]="isMegaMenuVisible"(mouseleave)="hideMegaMenu()" >
      <div class="row">
            <div class="col-3">
              
            
          <button class="menuButton " mat-button *ngFor="let data of StateNameList.slice(0,13)"
          (click)="routingState(data)">{{data.StateName}}</button>
        </div>
        <div class="col-3">
              
            
          <button class="menuButton col-3" mat-button *ngFor="let data of StateNameList.slice(13,26)"
          (click)="routingState(data)">{{data.StateName}}</button>
        </div>
        <div class="col-3">
              
            
          <button class="menuButton col-3" mat-button *ngFor="let data of StateNameList.slice(26,39)"
          (click)="routingState(data)">{{data.StateName}}</button>
        </div>
        <div class="col-3">
              
            
          <button class="menuButton col-3" mat-button *ngFor="let data of StateNameList.slice(39,52)"
          (click)="routingState(data)">{{data.StateName}}</button>
        </div>
      </div>
          </div>
   
    <a id="brightspot" class="skewDiv" (click)="Gotospot()" >
      <span class="d-flex  flex-column text-nowrap">
         <div class="d-block">Bright Spots</div>  
       <!--   <div class="d-flex align-items-center gap-2"></div> -->
      </span>
    </a>
    <a class="skewDiv" id="StateofLocal" (click)="GotoLocalNewsProject()">
      <span>State of Local News <br>Project</span>
    </a>

  </div>
</div>
